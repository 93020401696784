const ContentPageHeader = () => {
    const targetClass = '.content-page-header';
    const calculateContentPageHeader = () => {
        const menuHeight = jQuery('.storefront-header--overlayed').outerHeight();
        jQuery(targetClass).css({
            paddingTop: menuHeight*2
        });
        const blockHeight = jQuery(targetClass).outerHeight();
        jQuery('.storefront-header--overlayed').css({
            height: blockHeight
        });
    };
    const init = () => {
        if ( ! jQuery(targetClass).length ) {
            return false;
        }
        calculateContentPageHeader();
    };
    return init();
};