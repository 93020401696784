const CheckoutTermServiceModal = () => {
    const trigger = () => {
        console.log(1);

        const siteLogoImgUrl = jQuery('.storefront-header__site-logo img.standard').attr('src');

        jQuery('body').append( modalJs.getHtml() );
        const logo = `<img src="${siteLogoImgUrl}" />`;
        const html = `<div class="modal-content__title">${siteObject.checkoutModalData.title}</div>${siteObject.checkoutModalData.content}`;
        const acceptButtonHtml = '<div class="py-5"><span id="modal-accept" class="btn btn-primary btn-block">Accept</span></div>';
        jQuery('.modal-content').html(logo + html + acceptButtonHtml);

        jQuery('#modal').fadeIn( modalJs.fadeInSpeed );
        jQuery('#modal .close, #modal-accept').click( modalJs.closeModal );

        // modalJs.activateCloseModal();
        modalJs.pauseScrolling();
    };
    const init = () => {
        if ( ! siteObject.checkoutModalShow ) {
            return false;
        }
        trigger();
    };
    return init();
};