const ArtistSearchFilters = () => {
    const initArtistSearchFilters = () => {
        jQuery('.content-artist-search__filter-btn[data-trigger="filters"]').click(function(e) {
            e.preventDefault();
            jQuery('.content-artist-search').toggleClass('content-artist-search--show-filters');
        });
        jQuery('.content-artist-search__filter-btn[data-trigger="sort"]').click(function(e) {
            e.preventDefault();
            jQuery('.content-artist-search').toggleClass('content-artist-search--show-sort');
        });
    };
    const init = () => {
        if ( ! jQuery('.content-artist-search').length ) {
            return false;
        }
        initArtistSearchFilters();
    };
    return init();
};