const SliderHorizontal = () => {
    const targetClass = '.slider-horizontal .slider-horizontal__wrap';
    const tinySlider = () => {
        document
            .querySelectorAll(targetClass)
            .forEach(slider => {
                const tnsSlider = tns({
                    container: slider,
                    items: 1,
                    autoplay: true,
                    nav: false,
                    controls: true,
                    edgePadding: 0,
                    gutter: 40,
                    center: true,
                    controlsText: [
                        '<span class="fa fa-long-arrow-left"></span>',
                        '<span class="fa fa-long-arrow-right"></span>'
                    ],
                });
            });
    };
    const init = () => {
        if ( ! jQuery(targetClass).length ) {
            return false;
        }
        tinySlider();
    };
    return init();
};