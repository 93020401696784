const MobileMenu = () => {
    const toggleMenu = () => {
        jQuery('.storefront-header__btn-menu .btn, .storefront-header-mobile-menu__btn-close .btn').click(function(e) {
            e.preventDefault();
            jQuery('.storefront-header-mobile-menu').toggleClass('show');
            jQuery('body').toggleClass('modal-showing');
        });
    };
    const init = () => {
        toggleMenu();
    };
    
    return init();
};