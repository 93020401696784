/**
 * site
 **/

// Initilize debugging with 'localStorage.debug = true' in your console
var log = bows('site');

var debounceActions = function () {
	// add JS function calls here for resize actions
	// ContentPageHeader();
};
var debounceCallable = debounce(debounceActions, 250);

jQuery(document).ready(function () {
	MobileMenu();
	SliderHorizontal();
	ContentPageHeader();
	ArtistSearchFilters();
	WCShipPricePerProduct();
	CheckoutTermServiceModal();
	EarlyAccessWCPass();
});

jQuery(window).resize(function () {
	debounceCallable();
});