const WCShipPricePerProduct = () => {
    const targetClass = '.custom-shipping';
    const toggle = () => {
        jQuery('input[name="custom-shipping__local-pickup-available"]').change(function(e) {
            jQuery('.custom-shipping__shipping-available').toggleClass('show');
        });
    };
    const init = () => {
        if ( ! jQuery(targetClass).length ) {
            return false;
        }
        toggle();
    };
    return init();
};